/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Input, Modal, Form, Select } from "antd";

const SearchEditForm = ({ form, onCancel, onEdit, categories, selectedSearch }) => {
  const [category, setCategory] = useState(selectedSearch ? selectedSearch.category : "");

  return (
    <Modal visible title="Edit saved search" okText="Save" onCancel={onCancel} onOk={(e) => onEdit(e, { category })}>
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Title is required" }]}
          initialValue={selectedSearch.title}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Category">
          <Select id="category" value={category} onChange={setCategory}>
            {Object.entries(categories).map(([id, { title }]) => (
              <Select.Option key={id} value={id}>
                {title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SearchEditForm;
