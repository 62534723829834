import React from "react";
import { Modal } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function HeaderActions({ category, updateCategoryName, deleteCategory }) {
  return (
    <>
      {category.user_id && (
        <>
          <EditOutlined
            style={{ marginRight: 8 }}
            onClick={(e) => {
              e.stopPropagation();
              updateCategoryName();
            }}
          />
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                centered: true,
                title: "Are you sure?",
                content: `This will delete the category "${category.name}" and the favorites associated with it.`,
                onOk: deleteCategory,
              });
            }}
          />
        </>
      )}
    </>
  );
}
