import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Form, Modal, Input } from "antd";

import Services from "../../services";
import Helpers from "../../services/helpers";
import Toaster from "../../services/toaster";

const EditCategoryModal = ({ visible, id, name, onCancel, onUpdate }) => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await Services.updateFavoriteCategory(id, { name: value });

      if (response.error) {
        return Toaster.ErrorNotification("Unable to update favorite.");
      }

      Toaster.SuccessNotification("Category updated!");
      onUpdate(id, value);
      onCancel();
    } catch (e) {
      Helpers.handleApiError(e, router);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (name) {
      setValue(name);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="Update Favorite Category"
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{ disabled: loading }}
    >
      <Form labelAlign={"left"}>
        <Form.Item label="Name">
          <Input disabled={loading} onChange={(e) => setValue(e.target.value)} value={value} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
