import React from "react";
import { MenuOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { getListDragStyle, getItemStyle } from "../dragAndDropStyles";
import { genHref } from "../../utils/savedSearch";

const DraggableSavedSearchList = ({ type, categoryId, searches, handleSearchSelect, deleteSearchById }) => (
  <Droppable droppableId={categoryId}>
    {(provided, snapshot) => (
      <div {...provided.droppableProps} ref={provided.innerRef} style={getListDragStyle(snapshot.isDraggingOver)}>
        {searches.map((searchItem, index) => {
          const href = genHref(type, searchItem);

          return (
            <Draggable key={searchItem.id} draggableId={searchItem.id + ""} index={index}>
              {(provided, snapshot) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={{
                    ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                    minHeight: 40,
                  }}
                >
                  <div style={{ flex: 0, paddingRight: "5px" }}>
                    <MenuOutlined size="large" style={{ color: "silver" }} />
                  </div>
                  <a
                    href={href}
                    title={searchItem.title}
                    style={{
                      maxWidth: "calc(100% - 40px)",
                      whiteSpace: "nowrap",
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: 5,
                      flex: 1,
                    }}
                  >
                    {searchItem.title}
                  </a>
                  <div
                    style={{
                      flex: 0,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => handleSearchSelect(searchItem, index)}
                      style={{ marginRight: "5px" }}
                    />
                    <Button
                      type="danger"
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => deleteSearchById(searchItem.id)}
                    />
                  </div>
                </div>
              )}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default DraggableSavedSearchList;
