import React from "react";
import { AffiliateLink } from "./AffiliateLink";
import { SaveToFavorites } from "./favorites/SaveToFavorites";
import { FAVORITE_TYPES } from "../globals";
import { ChartLink } from "./ChartLink";
import AddToCollectionButton from "./personalCollection/addToCollectionButton";
import PopulationDisplay from "./PopulationDisplay";
import AlertLink from "./AlertLink";
import { ENTITY_TYPES } from "utils/constants";

const LeaderboardCardTitle = ({
  text,
  query,
  queryRangeId,
  cardId,
  showFavoriteIcon = true,
  showMobileTitle = true,
  populationData = null,
  openAlertModal,
  hasAlert = false,
  entityType = ENTITY_TYPES.CARD,
  dashboardComputerFormat = false,
}) => {
  return (
    <>
      {showMobileTitle && <span className="hidden-desktop inline-table-label">Card: </span>}
      <div style={dashboardComputerFormat ? { display: "flex" } : {}}>
        <div>
          <span>{text}</span>
          {populationData && populationData.population_count && populationData.population_last_updated && (
            <PopulationDisplay
              id={cardId}
              pop={populationData.population_count}
              pop_date={populationData.population_last_updated}
              serialNumber={populationData.serialNumber}
            />
          )}
        </div>
        <div className="action-button-container">
          <ChartLink queryRangeId={queryRangeId} cardId={cardId} entityType={entityType} className="card-action-icon" />
          <AffiliateLink abbreviated query={query} className="card-action-icon" />
          {showFavoriteIcon && (
            <SaveToFavorites
              className="card-action-icon"
              foreignId={cardId}
              type={
                {
                  [ENTITY_TYPES.CARD]: FAVORITE_TYPES.CARD,
                  [ENTITY_TYPES.PLAYER]: FAVORITE_TYPES.PLAYER,
                  [ENTITY_TYPES.SEALED_WAX]: FAVORITE_TYPES.SEALED_WAX,
                }[entityType]
              }
              abbreviated="true"
            />
          )}
          <AddToCollectionButton cardId={cardId} entityType={entityType} className="card-action-icon" />
          <AlertLink openAlertModal={() => openAlertModal(cardId, text)} cardId={cardId} hasAlert={hasAlert} />
        </div>
      </div>
    </>
  );
};

export { LeaderboardCardTitle };
