import React, { useState, useEffect } from "react";
import { Typography } from "antd";

import Services from "../../services";
import { useUserContext } from "../contexts/usercontext";

const CalculationRunningStatus = () => {
  const { user } = useUserContext();

  const [isCalculationRunning, setIsCalculationRunning] = useState(false);

  const checkStatus = async () => {
    // disable for regular users
    if (!user?.is_admin) {
      return;
    }

    const jobStatus = await Services.isCalculationRunning();

    if (jobStatus && jobStatus.running) {
      setIsCalculationRunning(true);
    }
  };

  useEffect(() => {
    checkStatus();
  }, []);

  if (!isCalculationRunning) {
    return null;
  }

  return (
    <div className="alerts" style={{ marginBottom: "1rem" }}>
      <Typography.Text style={{ color: "red", align: "center", fontSize: "22px" }}>
        The data is currently being processed. During this time you may see irregularities in the data. Please check
        back in 5 minutes.
      </Typography.Text>
    </div>
  );
};

export default CalculationRunningStatus;
