import React from "react";
import { DownSquareOutlined, RightSquareOutlined } from "@ant-design/icons";
import classNames from "classnames";
import PropTypes from "prop-types";

const ExpandIconTable = ({ prefixCls, expandable, expanded, record, onExpand }) => {
  const iconPrefix = `${prefixCls}-row-expand-icon`;

  if (!expandable) {
    return null;
  }

  const Icon = expanded ? DownSquareOutlined : RightSquareOutlined;

  return (
    <Icon
      onClick={(e) => {
        onExpand(record, e);
        e.stopPropagation();
      }}
      className={classNames(iconPrefix, {
        [`${iconPrefix}-spaced`]: !expandable,
        [`${iconPrefix}-expanded`]: expandable && expanded,
        [`${iconPrefix}-collapsed`]: expandable && !expanded,
      })}
      style={{ border: 0, backgroundColor: "transparent" }}
      size="small"
    />
  );
};

ExpandIconTable.propTypes = {
  prefixCls: PropTypes.string.isRequired,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  record: PropTypes.object.isRequired,
  onExpand: PropTypes.func.isRequired,
};

export default ExpandIconTable;
