import React, { useEffect, useRef, useState } from "react";
import { BarsOutlined, SearchOutlined, TagOutlined } from "@ant-design/icons";
import { Table, Button, Dropdown, Tooltip, Collapse, Input, Space } from "antd";
import { useRouter } from "next/router";

import { EMPTY_ALERT_MODAL_DATA, ENTITY_TYPES } from "utils/constants";
import { FAVORITE_TYPES } from "../../globals";
import AddAlertModal from "../modals/AddAlertModal";
import Services from "../../services";
import Helpers from "../../services/helpers";
import Toaster from "../../services/toaster";
import useTable from "../hooks/useTable";
import useMediaQuery from "../hooks/useMediaQuery";
import ExpandIconTable from "../ExpandIconTable";
import { LeaderboardCardTitle } from "../LeaderboardCardTitle";
import { FavoritesOptionsMenu } from "./FavoritesOptionsMenu";
import HeaderActions from "./HeaderActions";

const averages = [
  { dataIndex: "last7", title: "Last 7 Days" },
  { dataIndex: "last30", title: "Last 30 Days" },
  { dataIndex: "last90", title: "Last 90 Days" },
];

export const Favorites = ({
  dataSource = { data: [], totalCount: 0 },
  updateDataSource,
  type,
  category,
  categories,
  isCollapsed,
  toggleCollapseState,
  onDeleteCategory,
  moveFavoriteBetweenCategories,
  updateCategoryName,
  entityType = ENTITY_TYPES.CARD,
}) => {
  const router = useRouter();
  const searchInputRef = useRef();
  const tableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [alertModalData, setAlertModalData] = useState({ visible: false, cardId: null, name: "" });
  const { isFirstRender, tableProps, currentPage, pageSize, goToPage, sortBy, filters } = useTable({
    defaultPageSize: 10,
    totalCount: dataSource.totalCount,
    initialSort: {
      sortBy: "title",
      orderBy: "asc",
    },
    initialFilters: {
      title: searchText || undefined,
    },
  });
  const isMobile = useMediaQuery("(max-width: 768px)");

  const openAlertModal = (entityId, name) => {
    const identifier = {
      [ENTITY_TYPES.CARD]: "cardId",
      [ENTITY_TYPES.PLAYER]: "cardId",
      [ENTITY_TYPES.SEALED_WAX]: "sealedWaxId",
    }[entityType];

    setAlertModalData({
      visible: true,
      [identifier]: entityId,
      name,
    });
  };

  const closeAlertModal = () => {
    setAlertModalData(EMPTY_ALERT_MODAL_DATA);
  };

  const handleUpdateAlert = () => {
    const newData = dataSource.data.map((favorite) => {
      if (
        (type === FAVORITE_TYPES.CARD && favorite.foreign_id === alertModalData.cardId) ||
        (type === FAVORITE_TYPES.SEALED_WAX && favorite.foreign_id === alertModalData.sealedWaxId)
      ) {
        favorite.has_alert = true;
      }

      return favorite;
    });

    updateDataSource({ ...dataSource, data: newData });
  };

  const resetTable = () => {
    if (!isFirstRender) {
      if (currentPage === 1) {
        fetchFavorites();
      } else {
        goToPage(1);
      }
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedItems) => {
      setSelectedRowsIds(selectedItems.map(({ id }) => id));
      setSelectedRowKeys(selectedRowKeys);
    },
    selections: [
      {
        key: "all",
        text: "Select All",
        onSelect: (rowkeys) => {
          setSelectedRowsIds(rowkeys.map((key) => Number(key.split("-")[0])));
          setSelectedRowKeys(rowkeys);
        },
      },
      {
        key: "deselect",
        text: "Deselect All",
        onSelect: () => {
          setSelectedRowKeys([]);
        },
      },
      ...(selectedRowKeys.length
        ? [
            {
              key: "delete",
              text: <span style={{ color: "red" }}>Delete Selected</span>,
              onSelect: async () => {
                await deleteFavorite(selectedRowsIds);
              },
            },
          ]
        : []),
    ],
  };

  const fetchFavorites = async () => {
    try {
      setLoading(true);
      const response = await Services.getFavoritesByCategory(category.id, {
        type,
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
        ...filters,
        ...sortBy,
      });

      if (response.message) {
        Toaster.ErrorNotification(response.message);
      }

      const newDataSource = {
        data: response.error ? [] : response.data,
        totalCount: response.error ? 0 : response.totalCount,
      };

      updateDataSource({
        ...newDataSource,
        data: newDataSource.data.map(({ card, sealed_wax, ...favorite }) => {
          const row = { ...favorite, key: favorite.id };
          const [entity, stats] = {
            [FAVORITE_TYPES.CARD]: [card, card?.historical_stats],
            [FAVORITE_TYPES.PLAYER]: [card, card?.historical_stats],
            [FAVORITE_TYPES.SEALED_WAX]: [sealed_wax, sealed_wax?.sealed_wax_stats],
          }[type];

          if (type === FAVORITE_TYPES.CARD) {
            row.photo_image_url = card.photo?.image_url;
            row.player_name = card.player?.name;
            row.has_alert = entity.has_alert || false;
            row.query = entity.query;
            row.population_count = entity.population_count;
            row.population_last_updated = entity.population_last_updated;
            row.serial_number = entity.serial_number;
            row.last_avg_price = stats?.last_avg_price;
            row.last7 = stats?.last7;
            row.last30 = stats?.last30;
            row.last90 = stats?.last90;
            const replaceRegex = new RegExp(`^${row.player_name} ?`);
            row.title_without_name = row.title?.replace(replaceRegex, "");
          } else if (type === FAVORITE_TYPES.SEALED_WAX) {
            row.last_avg_price = stats?.last_avg_price;
            row.last7 = stats?.last7;
            row.last30 = stats?.last30;
            row.last90 = stats?.last90;
          }

          return row;
        }),
      });
      setHasFetched(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteFavorite = async (favoriteIds) => {
    try {
      const response = await Services.deleteFavorite(favoriteIds);
      if (response && response.error && response.status !== 404) {
        return Toaster.ErrorNotification("Error when deleting favorite.", response.error);
      }
      setSelectedRowsIds([]);
      resetTable();

      if (favoriteIds.length === 1) {
        return Toaster.SuccessNotification("Favorite deleted.");
      }
      const { deletedIds } = response;

      if (deletedIds.length !== favoriteIds.length) {
        return Toaster.SuccessNotification(`${deletedIds.length}/${favoriteIds.length} favorites deleted.`);
      }

      return Toaster.SuccessNotification("Favorites deleted.");
    } catch (error) {
      console.error(error);
      Helpers.handleApiError(error, router);
    }
  };

  const updateFavoriteCategory = (favorite) => async (categoryId) => {
    try {
      const res = await Services.updateFavorite(favorite.id, { category_id: categoryId });

      if (res && res.error) {
        return Toaster.ErrorNotification("Failed to update category");
      }

      moveFavoriteBetweenCategories({ ...favorite, category_id: categoryId }, category.id);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCategory = async () => {
    try {
      setLoading(true);
      const res = await Services.deleteFavoriteCategory(category.id);

      if (res && res.error) {
        return Toaster.ErrorNotification(res.error);
      }

      onDeleteCategory(category.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumns = () => {
    const columns = [];

    if (entityType === ENTITY_TYPES.CARD) {
      columns.push({
        title: "Player",
        dataIndex: "player_name",
        key: "player_name",
        sorter: () => null,
        width: 210,
        render: (text, record) => {
          const imgSrc = record.photo_image_url ? `${record.photo_image_url}-S` : "/no-image.png";

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img width={60} src={imgSrc} alt={record.player} style={{ marginRight: 10 }} />
              <span className="hidden-desktop inline-table-label">Player: </span>
              <div>{text}</div>
            </div>
          );
        },
      });
    }

    columns.push({
      title: "Title",
      dataIndex: entityType === ENTITY_TYPES.CARD && !isMobile ? "title_without_name" : "title",
      sorter: () => null,
      defaultSortOrder: "ascend",
      width: "45%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: null,
      render: (text, record) => {
        if (type === FAVORITE_TYPES.PLAYER) {
          return (
            <>
              {text}{" "}
              <Tooltip title="Check Player Price Movements">
                <a href={`/playerleaderboard?player=${record.foreign_id}`} target="_blank" rel="noopener noreferrer">
                  <TagOutlined className="card-action-icon" />
                </a>
              </Tooltip>
            </>
          );
        } else {
          return (
            <LeaderboardCardTitle
              dashboardComputerFormat={!isMobile}
              text={text}
              query={record.query}
              cardId={record.foreign_id || record.id}
              entityType={entityType}
              showFavoriteIcon={false}
              showMobileTitle={false}
              populationData={{
                population_count: record.population_count,
                population_last_updated: record.population_last_updated,
                serialNumber: record.serial_number,
              }}
              hasAlert={record.has_alert}
              openAlertModal={openAlertModal}
            />
          );
        }
      },
    });

    if ([FAVORITE_TYPES.CARD, FAVORITE_TYPES.SEALED_WAX].includes(type)) {
      columns.push({
        title: "Last Avg Sale Price",
        dataIndex: "last_avg_price",
        key: "last_avg_price",
        sorter: () => null,
        render: (text) => {
          return (
            <>
              <span className="hidden-desktop inline-table-label">Last Avg Sale Price: </span>
              {Helpers.formatMoney(text)}
            </>
          );
        },
      });
    }

    columns.push(
      ...averages.map((average) => ({
        ...average,
        sorter: () => null,
        render: (value) => {
          return value < 0 ? (
            <>
              <span className="hidden-desktop inline-table-label">{average.title}: </span>
              <strong style={{ color: "red" }}>{Helpers.formatMoney(value, 2, "")}%</strong>
            </>
          ) : (
            <>
              <span className="hidden-desktop inline-table-label">{average.title}: </span>
              <strong style={{ color: "green" }}>+{Helpers.formatMoney(value, 2, "")}%</strong>
            </>
          );
        },
      })),
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => {
          return (
            <>
              <span className="hidden-desktop inline-table-label">Actions: </span>
              <Dropdown
                trigger={["click"]}
                overlay={() =>
                  FavoritesOptionsMenu({
                    updateCategory: updateFavoriteCategory(record),
                    deleteFavorite: () => deleteFavorite([record.id]),
                    type,
                    categories,
                  })
                }
              >
                <Button shape="circle" ghost type="primary">
                  <BarsOutlined size="large" />
                </Button>
              </Dropdown>
            </>
          );
        },
      },
    );

    return columns;
  };

  useEffect(() => {
    fetchFavorites();
  }, [filters, pageSize, sortBy]);

  useEffect(() => {
    if (!isFirstRender) {
      fetchFavorites();
    }
  }, [currentPage]);

  // fetch if it was collapsed
  useEffect(() => {
    if (isFirstRender && isCollapsed) {
      fetchFavorites();
    }
  }, []);

  // fetch if it was uncollapsed
  useEffect(() => {
    if (!isFirstRender && isCollapsed && !hasFetched) {
      fetchFavorites();
    }
  }, [isCollapsed]);

  useEffect(() => {
    const color = selectedRowKeys.length ? "#004CFE" : null;

    if (tableRef?.current) {
      const selector = tableRef.current.querySelector(".ant-table-selection-extra .anticon");

      if (selector) {
        selector.style.color = color;
      }
    }
  }, [selectedRowKeys]);

  return (
    <>
      <AddAlertModal
        visible={alertModalData.visible}
        cardId={alertModalData.cardId}
        sealedWaxId={alertModalData.sealedWaxId}
        defaultData={{ name: alertModalData.name }}
        onCancel={closeAlertModal}
        onSubmit={handleUpdateAlert}
      />
      <Collapse activeKey={isCollapsed ? [category.id] : []} onChange={toggleCollapseState(category.id)}>
        <Collapse.Panel
          key={category.id}
          collapsible={loading && "disabled"}
          header={`${category.name} (${category.count || 0})`}
          extra={
            <HeaderActions
              category={category}
              deleteCategory={deleteCategory}
              updateCategoryName={updateCategoryName}
            />
          }
        >
          <Table
            ref={tableRef}
            className="ant-table-mobile"
            columns={getColumns()}
            dataSource={dataSource.data}
            rowSelection={rowSelection}
            rowKey={(record) => `${record.id}-${record.title}`}
            scroll={{ y: 500, x: "max-content" }}
            expandable={{
              expandIcon: ExpandIconTable,
            }}
            loading={loading}
            {...tableProps}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
