import React, { useEffect, useState } from "react";
import { useUserContext } from "../contexts/usercontext";
import { Modal } from "antd";
import Services, { setFirstLogin } from "../../services";

const FirstLoginModal = ({ children }) => {
  const { user } = useUserContext();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && user.first_login === false) {
      setVisible(true);
    }
  }, [user]);

  const handleCloseModal = async () => {
    setLoading(true);
    await setFirstLogin();
    Services.setUser({ ...user, first_login: true });
    setLoading(false);
    setVisible(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ loading }}
        okText={"Proceed to Market Movers"}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        title={"Walkthrough"}
        destroyOnClose={true}
      >
        <div style={{ width: "100%", position: "relative", paddingBottom: "56.25%" }}>
          <iframe
            style={{
              border: "none",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            src="https://www.youtube.com/embed/rjSkrOvBs-c"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
      {children}
    </>
  );
};

export default FirstLoginModal;
