const getListDragStyle = (isDraggingOver) => ({
  padding: ".5rem 0",
  minHeight: "100px",
  paddingBottom: isDraggingOver ? "20px" : ".5rem",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "#F6F6F6" : "white",
  borderRadius: isDragging ? 4 : 0,
  padding: ".25rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ...draggableStyle,
});

const getItemTableRowStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "#efefef" : "white",
  ...draggableStyle,
});

const titleStyle = {
  marginTop: "1rem",
  fontSize: "1rem",
  fontWeight: "bold",
};

const categoryStyle = {
  paddingLeft: "1rem",
  marginBottom: "1rem",
};

const categoryAddStyle = {
  display: "flex",
  alignItems: "center",
  paddingLeft: "1rem",
  marginBottom: "1rem",
};

const categoryAddStyleDropDown = {
  display: "flex",
  alignItems: "flex-start",
  paddingLeft: "0rem",
};

const layoutPlacement = {
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 100,
};

export {
  getListDragStyle,
  getItemStyle,
  titleStyle,
  categoryStyle,
  categoryAddStyle,
  getItemTableRowStyle,
  layoutPlacement,
  categoryAddStyleDropDown,
};
