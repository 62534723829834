import React, { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Row, Col, Spin, Collapse, Alert, Form } from "antd";
import { useRouter } from "next/router";
import { DragDropContext } from "react-beautiful-dnd";

import Helpers from "../../services/helpers";
import Services from "../../services";
import Toaster from "../../services/toaster";
import { logAndHandleAPIError } from "utils/errors";
import { COLLECTION_TYPES } from "../../globals";
import useGlobal from "../../store";
import { categoryAddStyle, categoryStyle } from "../dragAndDropStyles";
import {
  toggleCollapseState,
  getCollapseState,
  checkCollapseState,
  genUniqueId,
  CS_OPEN,
} from "../../utils/collapseState";
import { getAccessors, parseTypeTitle, getAlertLink, saveOrderUpdates } from "../../utils/savedSearch";
import SearchEditForm from "./SearchEditForm";
import DraggableSavedSearchList from "./DraggableSavedSearchList";

const SavedSearches = ({ type, anchor, dashboard }) => {
  const router = useRouter();

  const [newCategory, setNewCategory] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isSearchRunning, setIsSearchRunning] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { accessor, countAccessor } = getAccessors(type);
  const [showModal, setShowModal] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState();
  const [selectedDragUpdate, setSelectedDragUpdate] = useState();
  const [form] = Form.useForm();

  const toggleShowModal = () => {
    setShowModal((prev) => {
      if (!prev === false) {
        setSelectedSearch();
      }

      return !prev;
    });
  };

  const handleEditSearch = async (e, { category }) => {
    try {
      const values = await form.validateFields();

      const { id } = selectedSearch;
      const newSearch = { ...selectedSearch, ...values, category };
      await Services.updateSavedSearch(id, newSearch).catch((e) => {
        Helpers.handleApiError(e, router);
      });
      Toaster.SuccessNotification("Saved search updated!");
      globalActions.editById({ id, newSearch, objectType: type, collectionType: COLLECTION_TYPES.SAVED_SEARCH });

      globalActions.updateCollection({
        ...selectedDragUpdate,
        destination: { droppableId: category, index: globalState[accessor].categories[category].length },
        list: globalState[accessor],
        objectType: type,
        collectionType: COLLECTION_TYPES.SAVED_SEARCH,
      });

      toggleShowModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchSelect = (search, index) => {
    setSelectedSearch(search);
    setSelectedDragUpdate({
      source: { droppableId: search.category, index },
      draggableId: search.id,
    });
    toggleShowModal();
  };

  // Onload, try to find existing searches
  useEffect(() => {
    async function fetchSavedSearches() {
      const getSavedSearchesResponse = await Services.getSavedSearches(type).catch((error) => {
        logAndHandleAPIError(error, { router });
      });
      if (getSavedSearchesResponse && getSavedSearchesResponse.length) {
        getSavedSearchesResponse.forEach((r) => r.category && checkCollapseState(genUniqueId(r.type, r.category)));
        globalActions.addInitialItems({
          items: getSavedSearchesResponse,
          objectType: type,
          collectionType: COLLECTION_TYPES.SAVED_SEARCH,
        });
      }
      setHasLoaded(true);
    }

    globalState[accessor].categoryOrder.forEach((id) => checkCollapseState(genUniqueId(type, id)));
    fetchSavedSearches();
  }, []);

  const deleteSearchById = async (id) => {
    const deletedSearch = await Services.deleteSavedSearch(id).catch((e) => {
      Helpers.handleApiError(e, router);
    });

    if (deletedSearch.error) {
      Toaster.ErrorNotification("Error when removing search.", deletedSearch.error);
      return;
    }

    Toaster.SuccessNotification("Saved search deleted!");
    globalActions.deleteById({ id, objectType: type, collectionType: COLLECTION_TYPES.SAVED_SEARCH });
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!result.destination || (source.droppableId === destination.droppableId && source.index === destination.index))
      return;

    globalActions.updateCollection({
      list: globalState[accessor],
      source,
      destination,
      draggableId: +draggableId,
      objectType: type,
      collectionType: COLLECTION_TYPES.SAVED_SEARCH,
    });
    setIsSearchRunning(true);
    saveOrderUpdates(globalState[accessor], source, destination, setIsSearchRunning);
  };

  const addNewCategory = () => {
    if (newCategory === "") return Toaster.WarningNotification("You must type a category name.");
    checkCollapseState(genUniqueId(type, newCategory));

    const collectionType = COLLECTION_TYPES.SAVED_SEARCH;
    globalActions.addCategory({
      list: globalState[accessor],
      objectType: type,
      newCategory,
      collectionType,
    });
    setNewCategory("");
  };

  const genExtra = (category) => {
    return (
      <>
        <DeleteOutlined
          onClick={(event) => {
            event.stopPropagation();
            globalActions.removeCategory({
              list: globalState[accessor],
              objectType: type,
              categoryId: category.id,
              collectionType: COLLECTION_TYPES.SAVED_SEARCH,
            });
          }}
        />
      </>
    );
  };

  const noSearchesMessage = dashboard ? (
    <a href={getAlertLink(type)}>
      <Alert
        style={dashboard ? { marginLeft: "1rem" } : {}}
        message="No Saved Searches"
        description={`You don't have any saved ${parseTypeTitle(
          type,
        )} searches yet. When you do a search, save it so it appears here in the future.`}
        type="info"
        showIcon
      />
    </a>
  ) : (
    <div className="saved-well" id={anchor}>
      <h3>{`You don't have any saved ${parseTypeTitle(
        type,
      )} searches yet. When you do a search, save it so it appears here in the future.`}</h3>
    </div>
  );

  return hasLoaded ? (
    <div style={{ marginBottom: dashboard ? "2.5rem" : "1rem" }}>
      <h2 style={{ marginLeft: "1rem" }}>{dashboard ? parseTypeTitle(type) : "Your"} Saved Searches</h2>
      {showModal && selectedSearch && (
        <SearchEditForm
          form={form}
          onCancel={toggleShowModal}
          onEdit={handleEditSearch}
          selectedSearch={selectedSearch}
          categories={globalState[accessor].categories}
        />
      )}
      {!globalState[countAccessor] && !hasLoaded && <Spin size="large" />}
      {!globalState[countAccessor] && noSearchesMessage}
      {!!globalState[countAccessor] && (
        <>
          <Spin spinning={isSearchRunning} size="large">
            <Row>
              <div style={categoryAddStyle}>
                <Input
                  style={{ maxWidth: "300px" }}
                  placeholder="Category Name"
                  value={newCategory}
                  onChange={(event) => setNewCategory(event.target.value)}
                  onPressEnter={(event) => setNewCategory(event.target.value)}
                />
                <Button type="primary" onClick={addNewCategory}>
                  <PlusOutlined /> Add Category
                </Button>
              </div>
            </Row>
            <Row type="flex" id={anchor}>
              <DragDropContext onDragEnd={onDragEnd}>
                {globalState[accessor].categoryOrder.map((categoryId) => {
                  const category = globalState[accessor].categories[categoryId];
                  const searches = category.itemIds.map((id) => globalState[accessor].items[id]);
                  const shouldShowDeleteForCategory =
                    category.id !== "Saved_Searches" &&
                    globalState[accessor].categories[category.id].itemIds.length === 0;
                  const uniqueId = genUniqueId(type, categoryId);

                  return (
                    <Col xs={24} lg={8} sm={24} md={12} key={categoryId} style={categoryStyle}>
                      <Collapse onChange={() => toggleCollapseState(uniqueId)} defaultActiveKey={CS_OPEN}>
                        <Collapse.Panel
                          header={category.title}
                          key={getCollapseState(uniqueId) || CS_OPEN}
                          extra={shouldShowDeleteForCategory ? genExtra(category) : null}
                        >
                          <DraggableSavedSearchList
                            type={type}
                            categoryId={category.id}
                            searches={searches}
                            handleSearchSelect={handleSearchSelect}
                            deleteSearchById={deleteSearchById}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    </Col>
                  );
                })}
              </DragDropContext>
            </Row>
          </Spin>
        </>
      )}
      {!dashboard && <hr />}
    </div>
  ) : null;
};

export default SavedSearches;
