import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";

export const FavoritesOptionsMenu = ({ deleteFavorite, updateCategory, categories }) => {
  return (
    <Menu>
      {categories.map((category) => (
        <Menu.Item key={category.id}>
          <Button type="link" size="small" onClick={() => updateCategory(category.id)}>
            Add to {category.name}
          </Button>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Button type="danger" size="small" ghost onClick={deleteFavorite} style={{ border: "none", boxShadow: "none" }}>
          Delete <DeleteOutlined />
        </Button>
      </Menu.Item>
    </Menu>
  );
};
