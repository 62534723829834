import React from "react";
import { Spin } from "antd";

class Announcements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      title: "",
      isLoaded: false,
      content: [],
    };
  }

  componentDidMount() {
    fetch("https://www.sportscardinvestor.com/wp-json/wp/v2/pages/982?_embed=true", { cache: "no-cache" })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            title: result.title.rendered,
            content: result.content.rendered,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    return (
      <div className={"announcements-list"}>
        <h2 style={{ marginLeft: "1rem" }}>Announcements</h2>
        {!this.state.isLoaded && (
          <div style={{ textAlign: "left" }}>
            <Spin size={"large"} />
          </div>
        )}
        <div
          style={{ display: "flex", flexFlow: "column wrap", marginLeft: "1rem" }}
          dangerouslySetInnerHTML={{ __html: this.state.content }}
        ></div>
      </div>
    );
  }
}

export default Announcements;
