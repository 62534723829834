import React, { useEffect } from "react";
import * as FullStory from "@fullstory/browser";
import getConfig from "next/config";
import { useUserContext } from "../contexts/usercontext";

const getMembershipLevel = (membershipTier, isAdmin) => (isAdmin ? "ADMIN" : membershipTier.toUpperCase());

const UserTrackingWrapper = (props) => {
  const { publicRuntimeConfig } = getConfig();
  const orgId = publicRuntimeConfig.FULLSTORY_ORG;
  const apiHost = publicRuntimeConfig.API_HOST;
  const prodApiHost = publicRuntimeConfig.PROD_API_HOST;
  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      const { id, name, email, is_admin, membership_tier } = user;
      const isProd = apiHost === prodApiHost;
      if (isProd) {
        FullStory.init({
          orgId,
          host: "fullstory.com",
          script: "edge.fullstory.com/s/fs.js",
          devMode: !isProd,
          debug: !isProd,
        });

        FullStory.identify(id, {
          displayName: name,
          email: email,
          membership_level: getMembershipLevel(membership_tier, is_admin),
        });
      }
    }
  }, []);

  return <div>{props.children}</div>;
};

export default UserTrackingWrapper;
