import React from "react";
import Head from "next/head";
import { Layout, Row, Col } from "antd";

import AuthenticatedRoute from "../components/authenticatedroute";
import SavedSearches from "../components/search/SavedSearches";
import { SAVED_SEARCH_TYPES, FAVORITE_TYPES } from "../globals";
import SavedFavorites from "../components/favorites/SavedFavorites";
import CalculationRunningStatus from "../components/layouts/calculationrunning";
import Announcements from "../components/Announcements";
import FirstLoginModal from "../components/modals/FirstLoginModal";
import UserTrackingWrapper from "../components/wrappers/UserTrackingWrapper";
import { useModalContext } from "../components/contexts/modalcontext";
import PopCountChartModal from "../components/modals/PopCountChartModal";

import { ENTITY_TYPES } from "utils/constants";

const Dashboard = () => {
  const { isPopCountChartModalVisible, setIsPopCountChartModalVisible, popCountChartCardId } = useModalContext();

  return (
    <AuthenticatedRoute requiresAdmin={false}>
      <UserTrackingWrapper>
        <FirstLoginModal>
          <Layout className="dashboard-page">
            <Head>
              <title>Market Movers</title>
            </Head>
            <Row type="flex" style={{ padding: "2rem" }}>
              <PopCountChartModal
                cardId={popCountChartCardId}
                visible={isPopCountChartModalVisible}
                onCancel={() => setIsPopCountChartModalVisible(false)}
              />
              <Col lg={24} md={24} sm={24} xs={24}>
                <CalculationRunningStatus />
                <Announcements />
                <SavedFavorites type={FAVORITE_TYPES.CARD} dashboard />
                <SavedFavorites entityType={ENTITY_TYPES.PLAYER} type={FAVORITE_TYPES.PLAYER} dashboard />
                <SavedFavorites entityType={ENTITY_TYPES.SEALED_WAX} type={FAVORITE_TYPES.SEALED_WAX} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.PLAYER_CHARTS} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.SALES_LOOKUP} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.SET_AND_YEAR} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.SEALED_WAX} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.GRADE_RATIOS} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.PLAYER_RATIOS} dashboard />
                <SavedSearches type={SAVED_SEARCH_TYPES.VARIATION_RATIOS} dashboard />
              </Col>
            </Row>
          </Layout>
        </FirstLoginModal>
      </UserTrackingWrapper>
    </AuthenticatedRoute>
  );
};

export default Dashboard;
