import React, { useEffect, useState } from "react";
import { Button, Input, Row, Col, Spin, Alert } from "antd";
import { PlusOutlined, StarOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";

import Services from "../../services";
import Toaster from "../../services/toaster";
import { FAVORITE_TYPES } from "../../globals";
import useLocalStorage from "../hooks/useLocalStorage";
import { categoryAddStyle, categoryStyle } from "../dragAndDropStyles";
import { Favorites } from "./Favorites";
import EditCategoryModal from "./EditCategoryModal";
import { ENTITY_TYPES } from "utils/constants";
import { logAndHandleAPIError } from "utils/errors";

const NoFavoritesMessage = ({ type }) => (
  <Alert
    message="No Saved Favorites"
    style={{ marginLeft: "1rem" }}
    description={
      <div>
        You don&apos;t have any saved {parseTypeTitle(type)} Favorites yet. Click the{" "}
        <StarOutlined className="yellow" /> next to any {parseTypeTitle(type).toLowerCase()} to have it appear here.
      </div>
    }
    type="info"
    showIcon
  />
);

const initialModalValue = { visible: false, id: null, name: "" };

const SavedFavorites = ({ type, anchor, entityType = ENTITY_TYPES.CARD }) => {
  type = {
    [ENTITY_TYPES.CARD]: FAVORITE_TYPES.CARD,
    [ENTITY_TYPES.PLAYER]: FAVORITE_TYPES.PLAYER,
    [ENTITY_TYPES.SEALED_WAX]: FAVORITE_TYPES.SEALED_WAX,
  }[entityType];

  const router = useRouter();

  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [hasNoFavorites, setHasNoFavorites] = useState(false);
  const [dataSources, setDataSources] = useState({});
  const [categoryModalData, setCategoryModalData] = useState(initialModalValue);
  const [collapseState, setCollapseState, isReady] = useLocalStorage(`sci-favorites-${type}`, {});

  const fetchCategories = async () => {
    try {
      setLoading(true);

      const categories = await Services.getFavoriteCategories(type);
      const favoritesCount = categories.reduce((acc, category) => {
        const count = Number(category.count);

        if (Number.isInteger(count)) {
          return acc + count;
        }

        return acc;
      }, 0);

      if (favoritesCount === 0) {
        return setHasNoFavorites(true);
      }

      setCategories(categories);
      setDataSources(
        categories.reduce((acc, category) => ({ ...acc, [category.id]: { data: [], totalCount: 0 } }), {}),
      );
    } catch (error) {
      logAndHandleAPIError(error, { router });
    } finally {
      setLoading(false);
    }
  };

  const updateDataSource = (id) => (values) => {
    setDataSources((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        acc[key] = prev[key];

        if (Number(key) === id) {
          acc[key] = values;
        }

        return acc;
      }, {}),
    );
  };

  const addNewCategory = async () => {
    try {
      setIsCreatingCategory(true);

      const response = await Services.createFavoriteCategory({
        name: newCategory,
        type,
      });

      if (response && response.status === 409) {
        return Toaster.WarningNotification(`Category "${newCategory}" already exists!`);
      }

      setCategories((prev) => [...prev, response]);
      setDataSources((prev) => ({ ...prev, [response.id]: { data: [], totalCount: 0 } }));
      setNewCategory("");
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingCategory(false);
    }
  };

  const deleteCategory = (id) => {
    setCategories((prev) => prev.filter((c) => c.id !== id));
    setDataSources((prev) => {
      // eslint-disable-next-line no-unused-vars
      const { [id]: removed, ...rest } = prev;

      return rest;
    });
  };

  const toggleCollapseState = (id) => () => {
    setCollapseState((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        acc[key] = prev[key];

        if (String(key) === String(id)) {
          acc[key].collapsed = !acc[key].collapsed;
        }

        return acc;
      }, {}),
    );
  };

  const moveFavoriteBetweenCategories = (favorite, previousCategoryId) => {
    const newCategoryId = favorite.category_id;

    setDataSources((prev) => ({
      ...prev,
      [previousCategoryId]: {
        data: dataSources[previousCategoryId].data.filter((item) => item.id !== favorite.id),
        totalCount: dataSources[previousCategoryId].totalCount - 1,
      },
      [newCategoryId]: {
        data: [...dataSources[newCategoryId].data, favorite],
        totalCount: dataSources[newCategoryId].totalCount + 1,
      },
    }));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (!isReady || !categories.length) return;

    setCollapseState((prev) =>
      categories.reduce((acc, { id }) => {
        const defaultValue = { id, collapsed: true };
        acc[id] = defaultValue;

        if (String(id) in prev) {
          acc[id].collapsed = prev[id].collapsed;
        }

        return acc;
      }, {}),
    );
  }, [categories, isReady]);

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <h2 style={{ marginLeft: "1rem" }}>{parseTypeTitle(type)} Favorites</h2>
      {!loading && hasNoFavorites && <NoFavoritesMessage type={type} />}
      {!loading && !hasNoFavorites && (
        <Spin spinning={loading || isCreatingCategory} size="large">
          <EditCategoryModal
            {...categoryModalData}
            onUpdate={(id, newName) => {
              setCategories((prev) =>
                prev.map((category) => {
                  if (category.id === id) {
                    category.name = newName;
                  }

                  return category;
                }),
              );
            }}
            onCancel={() => setCategoryModalData(initialModalValue)}
          />

          <Row>
            <Input.Group compact style={categoryAddStyle}>
              <Input
                style={{ maxWidth: "300px" }}
                placeholder="Add A Category"
                value={newCategory}
                onChange={(event) => setNewCategory(event.target.value)}
                disabled={isCreatingCategory}
              />
              <Button type="primary" onClick={addNewCategory} disabled={isCreatingCategory}>
                <PlusOutlined /> Add
              </Button>
            </Input.Group>
          </Row>
          <Row type="flex" id={anchor}>
            {categories.map((category) => {
              return (
                <Col span={24} key={category.id} style={categoryStyle}>
                  <Favorites
                    {...{
                      type,
                      entityType,
                      category,
                      categories: categories.filter((c) => c.id !== category.id),
                      dataSource: dataSources[category.id],
                      updateDataSource: updateDataSource(category.id),
                      isCollapsed: collapseState[category.id] && collapseState[category.id].collapsed,
                      onDeleteCategory: deleteCategory,
                      toggleCollapseState,
                      moveFavoriteBetweenCategories,
                      updateCategoryName: () => setCategoryModalData({ visible: true, ...category }),
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Spin>
      )}
    </div>
  );
};

const parseTypeTitle = (type) => {
  switch (type) {
    case FAVORITE_TYPES.CARD:
      return "Card";
    case FAVORITE_TYPES.PLAYER:
      return "Player";
    case FAVORITE_TYPES.SEALED_WAX:
      return "Wax";
  }
};

export default SavedFavorites;
