export const CS_OPEN = "open";
export const CS_CLOSED = "closed";
const CSID = "sci-collapse-state";

export const genUniqueId = (type, id) => `${type}-${id}`;

export const getCollapseStateAll = () => {
  const stateString = window.localStorage.getItem(CSID);
  let stateJson;
  try {
    stateJson = JSON.parse(stateString);
    return stateJson;
  } catch (e) {
    console.error("get collapse state error", e);
    return null;
  }
};

export const getCollapseState = (id) => {
  const collapseState = getCollapseStateAll();

  return collapseState[id];
};

export const checkCollapseState = (id) => {
  const collapseState = getCollapseStateAll() || {};
  const keys = Object.keys(collapseState); // keys is an array of {id}s
  if (keys.indexOf(id) === -1) {
    collapseState[id] = CS_OPEN;

    const stringified = JSON.stringify(collapseState);
    window.localStorage.setItem(CSID, stringified);
  }
};

export const toggleCollapseState = (id) => {
  const collapseState = getCollapseStateAll();

  if (collapseState[id] === CS_OPEN) collapseState[id] = CS_CLOSED;
  else collapseState[id] = CS_OPEN;

  const stringified = JSON.stringify(collapseState);
  window.localStorage.setItem(CSID, stringified);
};
